import React from "react"
import { Themed } from "theme-ui"

const Profile = () => {
  return (
    <>
      <h1>Nothing! </h1>
      <Themed.a href="/">返回首页</Themed.a>
    </>
  )
}

export default Profile
